import request from './request'

function stageTrend(orgId,type) {
    return request({
        method: "get",
        url: `/client/monitoring/stageTrend?orgId=${orgId}&type=${type}`,
    })
}

function serviceProgress(orgId) {
    return request({
        method: "get",
        url: `/client/monitoring/serviceProgress?orgId=${orgId}`,
    })
}

function operateRisk(orgId) {
    return request({
        method: "get",
        url: `/client/monitoring/operateRisk?orgId=${orgId}`,
    })
}

function employeeAndClient(orgId) {
    return request({
        method: "get",
        url: `/client/monitoring/employeeAndClient?orgId=${orgId}`,
    })
}

function selectNextOrgByAccess(data) {
    return request({
        method: "post",
        url: `/client/groupMember/selectNextOrgByAccess`,
        data
    })
}

export{stageTrend,serviceProgress,operateRisk,employeeAndClient,selectNextOrgByAccess}
