<template>
    <div>
        <div class="card">
            <div class="head">
                运营风险（实时）
                <div class="right-content" v-show="this.groupOptionbs.length>0">
                    <van-dropdown-menu title-class="test2">
                        <van-dropdown-item v-model="chart3.formData.orgId" :options="groupOptionbs"
                            @change="(value)=>dropChange(value,3,null)" />
                    </van-dropdown-menu>
                </div>
            </div>
            <div v-show="chart3.isShow">
                <div class="content">
                    <div ref="charts3" style="width:100%;height:250px"></div>
                </div>
                <div class="table" v-show="chart3.data.data">
                    <table border="0">
                        <tr>
                            <td style="width:100px"></td>
                            <td class="center" v-for="(item,index) in chart3.data.titles" :key="index">{{item.name}}
                            </td>
                        </tr>
                        <tr v-for="(item,index) in chart3.data.data" :key="index">
                            <td>{{item.name}}</td>
                            <td class="center" v-for="(item2,index2) in chart3.data.titles" :key="index2">
                                {{item[item2.prop]}}</td>
                        </tr>
                    </table>
                </div>
            </div>
            <div v-show="!chart3.isShow" class="n-panel">
                无
            </div>
        </div>
        <van-divider />

        <div class="card">
            <div class="head">
                服务进度（实时）
                <div class="right-content" v-show="this.groupOptionbs.length>0">
                    <van-dropdown-menu title-class="test2">
                        <van-dropdown-item v-model="chart2.formData.orgId" :options="groupOptionbs"
                            @change="(value)=>dropChange(value,2,null)" />
                    </van-dropdown-menu>
                </div>
            </div>
            <div v-show="chart2.isShow">
                <div class="content">
                    <div ref="charts2" style="width:100%;height:250px"></div>
                </div>
                <div class="table" v-show="chart2.data.data">
                    <table border="0">
                        <tr>
                            <td style="width:100px"></td>
                            <td class="center" v-for="(item,index) in chart2.data.titles" :key="index">{{item.name}}
                            </td>
                        </tr>

                        <tr v-for="(item,index) in chart2.data.data" :key="index">
                            <td>{{item.name}}</td>
                            <td class="center" v-for="(item2,index2) in chart2.data.titles" :key="index2">
                                {{item[item2.prop]}}</td>
                        </tr>
                    </table>
                </div>
            </div>
            <div v-show="!chart2.isShow" class="n-panel">
                无
            </div>

        </div>
        <van-divider />

        <div class="card">
            <div class="head">
                阶段走势
                <div class="right-content" v-show="this.groupOptionbs.length>0">
                    <van-dropdown-menu title-class="test2">
                        <van-dropdown-item v-model="chart1.formData.orgId" :options="groupOptionbs"
                            @change="(value)=>dropChange(value,1,null)" />
                    </van-dropdown-menu>
                </div>
            </div>

            <div class="content">
                <div class="top-content">
                    <van-button :type="chart1.button1" size="mini" @click="dropChange(null,1,1)">近八周</van-button>
                    <van-button :type="chart1.button2" size="mini" @click="dropChange(null,1,2)">近三月</van-button>
                    <van-button :type="chart1.button3" size="mini" @click="dropChange(null,1,3)">近半年</van-button>
                </div>
                <div v-show="chart1.isShow">
                    <div ref="charts1" style="width:100%;height:250px"></div>
                </div>
                <div v-show="!chart1.isShow" class="n-panel">无</div>
            </div>

        </div>
        <van-divider />

        <div class="card">
            <div class="head">
                员工与客户（实时）
                <div class="right-content" v-show="this.groupOptionbs.length>0">
                    <van-dropdown-menu title-class="test2">
                        <van-dropdown-item v-model="chart4.formData.orgId" :options="groupOptionbs"
                            @change="(value)=>dropChange(value,4,null)" />
                    </van-dropdown-menu>
                </div>
            </div>
            <div v-show="chart4.isShow">
                <div class="content">
                    <div ref="charts4" style="width:100%;height:250px"></div>
                </div>
                <div class="table" v-show="chart4.data.data">
                    <table border="0">
                        <tr>
                            <td style="width:100px"></td>
                            <td class="center" v-for="(item,index) in chart4.data.titles" :key="index">{{item.name}}
                            </td>
                        </tr>
                        <tr v-for="(item,index) in chart4.data.data" :key="index">
                            <td>{{item.name}}</td>
                            <td class="center" v-for="(item2,index2) in chart4.data.titles" :key="index2">
                                {{item[item2.prop]}}</td>
                        </tr>
                    </table>
                </div>
            </div>
            <div v-show="!chart4.isShow" class="n-panel">无</div>
        </div>
    </div>

</template>

<script>
import * as echarts from 'echarts';
import {
    stageTrend,
    serviceProgress,
    operateRisk,
    employeeAndClient,
    selectNextOrgByAccess
} from '@/api/statistic.js'
export default {
    data() {
        return {
            chart1: {
                chart: null,
                button1: "info",
                button2: "default",
                button3: "default",
                isShow: true,
                formData: {
                    orgId: '0',
                    type: 1
                },
            },
            chart2: {
                chart: null,
                data: { data: null },
                isShow: true,
                formData: {
                    orgId: '0'
                },
            },
            chart3: {
                chart: null,
                data: { data: null },
                isShow: true,
                formData: {
                    orgId: '0'
                },
            },
            chart4: {
                chart: null,
                data: { data: null },
                isShow: true,
                formData: {
                    orgId: '0'
                },
            },
            groupOptionbs: [],


        };
    },
    mounted() {
        selectNextOrgByAccess().then(res => {
            if (res.success) {
                res.value.forEach(item => {
                    this.groupOptionbs.push({
                        text: item.name,
                        value: item.id
                    });
                });
            }
        });
        this.init1();
        this.init2()
        this.init3()
        this.init4()
    },
    methods: {
        dropChange(value, type, dateType) {
            var formData = this["chart" + type].formData;

            if (value != null) {
                formData.orgId = value;

            }

            switch (type) {
                case 1:
                    if (dateType != null) {
                        formData.type = dateType;
                        for (var i = 1; i <= 3; i++) {
                            if (i == dateType) {
                                this.chart1["button" + i] = "info";
                            } else {
                                this.chart1["button" + i] = "default";
                            }
                        }
                    }

                    this.init1();
                    break;
                case 2:
                    this.init2();
                    break;
                case 3:
                    this.init3();
                    break;
                case 4:
                    this.init4();
                    break;
            }
        },
        async init1() {
            var chart = this.chart1.chart;
            var legendData = ['一阶段', '二阶段', '未破冰', '签约', '终止', '结单'];
            var xAxisData = [];
            var seriesArray = [];
            var res = await stageTrend(this.chart1.formData.orgId, this.chart1.formData.type);
            this.chart1.isShow = res.value ? true : false;
            if (!this.chart1.isShow) {
                return;
            }
            res.value.forEach(item => {
                xAxisData.push(item.time);
            });
            legendData.forEach(item => {
                var sData = [];
                xAxisData.forEach(xItem => {
                    res.value.forEach(resItem => {
                        if (resItem.time == xItem) {
                            sData.push(resItem[item]);
                        }
                    });
                });
                seriesArray.push({
                    name: item,
                    type: 'line',
                    label: {
                        show: true,
                        position: 'inside',
                        backgroundColor: "inherit",
                        color: '#fff',
                        padding: 5,
                        width: 15,
                        height: 15,
                        borderRadius: 15
                    },
                    data: sData
                });

            });
            chart = chart == null ? echarts.init(this.$refs['charts1']) : chart;
            // 绘制图表
            chart.setOption({
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                legend: {
                    data: legendData
                },
                grid: {
                    top: 60,
                    left: '1%',
                    right: '3%',
                    bottom: '0',
                    containLabel: true
                },
                xAxis: {
                    offset: 8,
                    type: 'category',
                    data: xAxisData,
                    axisLabel: {
                        interval: 0,
                        rotate: 40
                    }
                },
                yAxis: {

                },
                series: seriesArray
            });

        },
        async init2() {
            var chart = this.chart2.chart;
            var res = await serviceProgress(this.chart2.formData.orgId);
            var legendData = [];
            var seriesArray = [];
            this.chart2.isShow = res.value ? true : false;
            if (!this.chart2.isShow) {
                return;
            }
            res.value.series.forEach(item => {
                legendData.push(item.name);
                seriesArray.push(item.data);
            });
            this.chart2.data = res.value;
            chart = chart == null ? echarts.init(this.$refs['charts2']) : chart;
            // 绘制图表
            chart.setOption({
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                grid: {
                    top: 30,
                    left: '1%',
                    right: '3%',
                    bottom: '1%',
                    containLabel: true
                },
                xAxis: {
                    type: 'category',
                    data: legendData
                },
                yAxis: {

                },
                series: [{
                    name: '数量',
                    type: 'bar',
                    label: {
                        show: true,
                        position: 'inside'
                    },
                    data: seriesArray
                }]
            });

        },
        async init3() {
            var chart = this.chart3.chart;
            var res = await operateRisk(this.chart3.formData.orgId);
            var legendData = [];
            var seriesArray = [];
            this.chart3.isShow = res.value ? true : false;
            if (!this.chart3.isShow) {
                return;
            }
            res.value.series.forEach(item => {
                legendData.push(item.name);
                seriesArray.push(item.data);
            });
            this.chart3.data = res.value;
            chart = chart == null ? echarts.init(this.$refs['charts3']) : chart;
            // 绘制图表
            chart.setOption({
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                grid: {
                    top: 30,
                    left: '1%',
                    right: '3%',
                    bottom: '1%',
                    containLabel: true
                },
                xAxis: {
                    type: 'category',
                    data: legendData
                },
                yAxis: {

                },
                series: [{
                    name: '数量',
                    type: 'bar',
                    label: {
                        show: true,
                        position: 'inside'
                    },
                    data: seriesArray
                }]
            });

        },
        async init4() {
            var chart = this.chart4.chart;
            var legendData = ['咨询师', '客户'];
            var xAxisData = [];
            var seriesArray = [];
            var res = await employeeAndClient(this.chart4.formData.orgId);
            this.chart4.isShow = res.value ? true : false;
            if (!this.chart4.isShow) {
                return;
            }
            res.value.seriesMap.forEach(item => {
                xAxisData.push(item.name);
            });

            legendData.forEach(item => {
                var sData = [];
                xAxisData.forEach(xItem => {
                    res.value.seriesMap.forEach(resItem => {
                        if (resItem.name == xItem) {
                            sData.push(resItem[item]);
                        }
                    });
                });
                seriesArray.push({
                    name: item,
                    type: 'bar',
                    label: {
                        show: true,
                        position: 'inside',
                        backgroundColor: "inherit",
                        color: '#fff',
                    },
                    data: sData
                });

            });
            this.chart4.data = res.value;
            chart = chart == null ? echarts.init(this.$refs['charts4']) : chart;
            // 绘制图表
            chart.setOption({
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                title: {
                    text: "咨询师客户比：" + res.value.empAndClientProportion,
                    right: 20,
                    textStyle: {
                        color: "#777",
                        fontSize: 14
                    }
                },
                legend: {
                    data: legendData,
                    left: 10,
                    formatter: function (name) {
                        var returnName = '';
                        if (name == "咨询师") {
                            return name + "　" + res.value.employeeCount;
                        } else if (name == "客户") {
                            return name + "　" + res.value.clientCount;
                        }

                    }
                },
                grid: {
                    top: 60,
                    left: '1%',
                    right: '3%',
                    bottom: '0',
                    containLabel: true
                },
                xAxis: {
                    type: 'category',
                    data: xAxisData
                },
                yAxis: {

                },
                series: seriesArray
            });

        }
    }
};
</script>

<style scoped lang="less">
/deep/.van-dropdown-menu__bar,
/deep/.van-dropdown-menu__title,
/deep/.van-dropdown-item__option {
    background: #fff;
    color: #333;
}
.view {
    background: #f2f3f4;
    color: #333;
}
.card {
    background: #fff;
    font-size: 14px;
    border-bottom: 1px solid #eee;

    .head {
        padding: 20px;
        position: relative;
        border-bottom: 1px solid #eee;
    }

    .content {
        padding: 5px;

        .top-content {
            text-align: center;
        }
    }

    .right-content {
        width: 200px;
        position: absolute;
        text-align: right;
        right: 5px;
        top: 10px;
    }
}

td {
    border: 1px solid #999;
    color: #666;
    padding: 3px;
    font-size: 14px;
}

.center {
    text-align: center;
}

.table {
    padding: 5px;
}

table {
    width: 100%;
    border-collapse: collapse;
}

.n-panel {
    padding: 10px;
    text-align: center;
}

/deep/.van-dropdown-menu__item {
    justify-content: flex-end;
}

/deep/.van-dropdown-menu__bar {
    box-shadow: none !important;
}
</style>